<template>
  <main-layout :is-loading="isLoading">
    <div class="minigames-page">
      <h1 class="minigames-page__title">
        {{ $t('minigames.tournamentsPageTitle') }}
      </h1>
      <template>
        <div class="minigames-page__desktop-filter">
          <MinigamesTabsFilter
            v-model="minigamesFilterGameId"
            id-key="id"
            :popular="games"
            @input="handleInputFilter"
          />
        </div>
        <div class="minigames-page__mobile-filter">
          <MinigamesDropdownFilter
            v-model="minigamesFilterGameId"
            id-key="id"
            :games="games"
            @input="handleInputFilter"
          />
        </div>
      </template>
      <div class="minigames-page__tablist">
        <TabList
          :start-tab-index="1"
          :tabs="currentTabs"
          @change-tab="handleChangeTab"
        />
      </div>
      <div class="minigames-page__tournaments">
        <template v-if="tournaments.length">
          <div class="minigames-page__table">
            <Table>
              <template #thead>
                <TableRow>
                  <TableData heading text-align="left">
                    {{ $t('global.tournament') }}
                  </TableData>
                  <TableData heading>
                    {{ $t('global.start') }}
                  </TableData>
                  <TableData heading>
                    {{ $t('global.end') }}
                  </TableData>
                  <TableData heading>
                    {{ $t('global.participants') }}
                  </TableData>
                  <TableData heading>
                    {{ $t('global.status') }}
                  </TableData>
                </TableRow>
              </template>
              <template #tbody>
                <TableRow v-for="tn in tournaments" :key="tn.id">
                  <TableData text-align="left">
                    <router-link
                      class="minigames-page__tournament-title"
                      :to="`/minigames/tournaments/${tn.id}`"
                    >
                      {{ tn.title }}
                    </router-link>
                  </TableData>
                  <TableData>{{ getDate(tn.startedAt) }}</TableData>
                  <TableData>{{ getDate(tn.finishedAt) }}</TableData>
                  <TableData>{{ tn.playersCount }}</TableData>
                  <TableData>
                    <template v-if="tn.status === 'future'">
                      {{ $t('tournaments.state_announced') }}
                    </template>
                    <template v-if="tn.status === 'active'">
                      <Button
                        tag="button"
                        :text="$t('global.doPlay')"
                        type="secondary"
                        size="regular"
                        long
                        @click="goToTournament(tn)"
                      />
                    </template>
                    <template v-if="tn.status === 'finished'">
                      {{ $t('tournament.tournament_is_finish') }}
                    </template>
                  </TableData>
                </TableRow>
              </template>
            </Table>
          </div>
          <div
            v-if="isShowPagination"
            class="minigames-page__pagination"
          >
            <ListItemPagination
              :items-amount="itemsAmount"
              :items-on-page-amount="itemOnPageAmount"
              :step="3"
              @change="handlePaginationChange"
            />
          </div>
        </template>
        <p v-else class="minigames-page__message">
          {{ $t('tournaments.tournamentsNotFound') }}
        </p>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MinigamesTabsFilter from '@components/v2/minigames/MinigamesTabsFilter.vue';
import MinigamesDropdownFilter from '@components/v2/minigames/MinigamesDropdownFilter.vue';
import TabList from '@components/v2/TabList.vue';
import Button from '@components/v2/ui/Button.vue';
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import ListItemPagination from '@components/v2/ListItemPagination.vue';
import { getDateString } from '@utils/time.js';
import { pushEvents } from '@utils/metricEvents.js';

export default {
  name: 'MinigamesPage',
  components: {
    MinigamesTabsFilter,
    MinigamesDropdownFilter,
    TabList,
    Table,
    TableRow,
    TableData,
    Button,
    ListItemPagination,
  },
  data() {
    return {
      isLoading: true,
      currentTabType: 'active',
      minigamesFilterGameId: 0,
      tournaments: [],
      games: [],
      pagination: {},
      currentPage: 1,
      itemOnPageAmount: 12,
      tabs: {
        general: [
          {
            title: this.$t('tournaments.tabs_upcoming'),
            type: 'future',
          },
          {
            title: this.$t('tournaments.tabs_current'),
            type: 'active',
          },
          {
            title: this.$t('tournaments.tabs_completed'),
            type: 'finished',
          },
        ],
        personal: [
          {
            title: this.$t('tournaments.variant_my'),
            type: 'my',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('profile', ['hasMailAccount', 'isLoggedIn']),

    fetchTournamentParams() {
      return {
        gameId: this.minigamesFilterGameId
          ? this.minigamesFilterGameId
          : null,
        page: this.currentPage,
      };
    },

    itemsAmount() {
      if (this.pagination.last) {
        return this.itemOnPageAmount * this.pagination.last;
      }
      return 1;
    },
    currentTabs() {
      if (this.isLoggedIn) {
        return [...this.tabs.general, ...this.tabs.personal];
      }
      return this.tabs.general;
    },

    isShowPagination() {
      return this.itemsAmount > this.itemOnPageAmount;
    },
    isValidParticipant() {
      return this.isLoggedIn && this.hasMailAccount;
    },
  },
  async created() {
    await this.fetchGames();
    this.isLoading = false;
  },
  methods: {
    playGameClickHandler(tn) {
      // ======================================================
      // METRICS МиниИгры. Клик на кнопку "Играть"
      const tmrParams = {
        category: 'pvp',
        action: 'minigames-play',
        label: tn.game.vkPlayGameId,
        url: window.location.href,
      };
      pushEvents('minigames-play', tmrParams);
      // ======================================================
    },
    goToTournament(tn) {
      if (window._tmr_vkp_gen && window.yaCounter88495267) {
        this.playGameClickHandler(tn);
      }

      let routeData = this.$router.resolve(
        `/minigames/tournaments/${tn.id}${
          this.isValidParticipant ? '/playground' : ''
        }`,
      );
      window.open(routeData.href, '_blank');
    },
    async fetchTournaments() {
      try {
        const response = await api.get(
          `v2/mini-games/tournaments/${this.currentTabType}`,
          {
            params: this.fetchTournamentParams,
          },
        );
        this.tournaments = response.data;
        this.pagination = response.pages;
      } catch (error) {
        this.tournaments = [];
        this.currentPage = 1;
      }
    },
    async fetchGames() {
      const response = await api.get('v2/mini-games/games');
      this.games = response.data;
    },

    getDate(seconds) {
      return getDateString(seconds, 'ru');
    },

    handleInputFilter(id) {
      this.minigamesFilterGameId = id;
      this.currentPage = 1;
      this.fetchTournaments();
    },
    handleChangeTab(data) {
      this.currentTabType = data.currentTab.type;
      this.currentPage = 1;
      this.fetchTournaments();
    },
    handlePaginationChange(eventData) {
      this.currentPage = eventData.currentPage;
      this.fetchTournaments();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.minigames-page {
  max-width: 1224px;
  width: 100%;
  padding: 40px 12px;
  margin: 0 auto;
}

.minigames-page__title {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 24px;

  @include min-tablet() {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 40px;
  }
}

.minigames-page__desktop-filter {
  display: none;

  @include min-tablet() {
    display: block;
    margin-bottom: 24px;
  }
}

.minigames-page__mobile-filter {
  margin-bottom: 16px;

  @include min-tablet() {
    display: none;
  }
}

.minigames-page__tablist {
  margin-bottom: 8px;
}

.minigames-page__message {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background: #2d2d30;
  padding: 20px 12px;
  border-radius: 6px;
}

.minigames-page__table {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.minigames-page__tournament-title {
  color: inherit;
  display: block;
  min-width: 400px;

  &:hover {
    text-decoration: none;
  }
}
</style>
